<app-dialog [errorObject]="errorObject">
    <app-dialog-title i18n="@@subscription-card-seat-allocation-options-title">Subscription parameters</app-dialog-title>
    <app-dialog-message i18n="@@subscription-mode-dialog--message">
        Please select the subscription allocation mode.
    </app-dialog-message>
    <app-dialog-content>
        <app-radio-group class="flex-row flex-default-gap flex-wrap app-options-highlight"
                         key="choose-subscription-mode"
                         [value]="data.subscription.allocationMode"
                         (selectedValueChanged)="onOptionChanged($event)">
            <app-radio-select-card-button [value]="SubscriptionAllocationMode.Automatic"
                                          [cardStyle]="RadioSelectCardButtonStyle.Normal">
                <span card-title>
                    <div class="title-multilines"
                         i18n="@@subscription-card-seat-allocation-auto-name">(DEFAULT) First come, first served</div>
                </span>
                <span card-text
                      i18n="@@subscription-card-seat-allocation-auto-desc">Tokens are
                    assigned automatically to profiles until there are none left.</span>
            </app-radio-select-card-button>

            <app-radio-select-card-button [value]="SubscriptionAllocationMode.Manual"
                                          [cardStyle]="RadioSelectCardButtonStyle.Normal">
                <span card-title>
                    <div class="title-multilines"
                         i18n="@@subscription-card-seat-allocation-manual-name">Manage the assignment of tokens</div>
                </span>
                <span card-text
                      i18n="@@subscription-card-seat-allocation-manual-desc">It is the
                    admin of the organization who decides who the tokens go to. Tokens are managed from the "Organizations" menu.</span>
            </app-radio-select-card-button>
        </app-radio-group>
    </app-dialog-content>
    <button mat-stroked-button
            appDialogAction
            matDialogClose
            i18n="@@common-cancel">
        Cancel
    </button>
    <button mat-flat-button
            appDialogAction
            color="primary"
            [disabled]="!(selectedMode || false)"
            (click)="update()">
        <span i18n="@@common-save">Save</span>
    </button>
</app-dialog>